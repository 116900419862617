import React from "react"
import { Helmet } from "react-helmet"
import "../components/layout.css"
import 'typeface-open-sans/index.css'
import Footer from "../components/footer.js"
import NavBar from "../components/navbar.js"
import StandardForm from "../components/standardform.js"
import config from "../config.yaml"


const RemembranceFormTemplate = ({ pageContext }) => (
  <>
    <Helmet>
      <meta charSet="utf-8" />
      <title>Submit a memory</title>
    </Helmet>
    <NavBar navLinks={config.navLinks} />
    <StandardForm name="remembrance">
      <p>
        <label htmlFor="author-input">Your Name</label>
        <input type="text" name="author" id="author-input"/>
      </p>
      <p>
        <label htmlFor="text-input">Remembrance</label>
        <textarea id="text-input" name="text" placeholder="Please enter a short paragraph describing your memories of Ros. After a little time, it will appear on the password-protected guest book page of this site."/> 
      </p>
      <p>
        <label htmlFor="relationship-input">Relationship to Ros</label>
        <input type="text" name="relationship" id="relationship-input"/>
      </p>
    </StandardForm>
    <Footer />
  </>
);


export default RemembranceFormTemplate;