import React from "react";

const StandardForm = ({ name, children }) => (
  <form name={name} className="submission-form" method="POST" data-netlify="true">
    {children}
    <input type="hidden" name="form-name" value={name}/>
    <div className="button-wrapper">
      <button type="submit">Send</button>
    </div>
  </form>
);

export default StandardForm;